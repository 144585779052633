import React from 'react';
import { CampaignBanner, CampaignBannerColor, CampaignBannerVariant } from '@naf/banner';
import * as S from './Styles';
import BlockContent from '../../components/block-content/BlockContent';
import { useResponsiveImageSize } from '../../hooks/useResponsiveImageSize';
import useSelector from '../../redux/typedHooks';
import { cloudinaryImage } from '../../utils/imageUrl';
import { Campaign } from '../../../../types/benefitsType';

type Props = {
  campaign: Campaign[];
  handleCtaBlankExternalTracking: any;
};

const CampaignBannerMapper = ({ campaign, handleCtaBlankExternalTracking }: Props) => {
  const responsiveImagesSize = useResponsiveImageSize(675);
  const application = useSelector((state) => state.application);
  return campaign.map((camp) => {
    const imageWidth = responsiveImagesSize ? responsiveImagesSize.width : undefined;
    const imageHeight = responsiveImagesSize ? responsiveImagesSize.height : undefined;
    const campImage =
      (camp.image &&
        camp.image.publicId &&
        imageWidth &&
        cloudinaryImage(camp.image?.publicId, application, imageWidth, imageHeight, undefined, 1.504)) ||
      undefined;
    return (
      <S.BannerWrapper key={camp.id}>
        <CampaignBanner
          label={camp.label}
          title={camp.name}
          color={CampaignBannerColor.Dew}
          img={
            camp.image && {
              src: campImage,
              alt: camp.image?.source_altText || '',
            }
          }
          variant={CampaignBannerVariant.Small}
          ctaButton={
            camp.campaignCallToAction &&
            camp.campaignCallToAction.buttonText && {
              text: camp.campaignCallToAction.buttonText,
              href: camp.campaignCallToAction.url,
              onClick: handleCtaBlankExternalTracking(camp.campaignCallToAction),
              blank: true,
            }
          }
        >
          <BlockContent value={camp.mainText} />
        </CampaignBanner>
      </S.BannerWrapper>
    );
  });
};

export default CampaignBannerMapper;
