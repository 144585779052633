import axios from 'axios';

export const fetchInternalLink = async (url: string, key: string, token?: string) => {
  const link = await axios.get<string>(url, {
    headers: {
      'Ocp-Apim-Subscription-Key': key,
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
  return link.data;
};
