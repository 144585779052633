import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { TextVariant } from '@naf/text';
import { LoginButton } from '../../components/my-naf/Login/Login';
import * as S from './Styles';

type Props = {
  onClickBecomeAMember: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const CtaButtonWithDiscountInfo = ({ onClickBecomeAMember }: Props) => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  const becomeMemberPath = '/bli-medlem';

  return (
    <>
      <S.DiscountInfoText tag="p" variant={TextVariant.ArticleText}>
        {isAuthenticated
          ? 'Du må være medlem for å få tilgang til rabatt'
          : 'Logg inn for å kunne ta i bruk din medlemsfordel.'}
      </S.DiscountInfoText>
      {!isAuthenticated && <LoginButton buttonText="Logg inn" returnUrl={history.location.pathname} />}
      <S.StyledButton
        applyLeftMargin={!isAuthenticated}
        onClick={onClickBecomeAMember}
        href={becomeMemberPath}
        variant="outline"
      >
        Bli medlem
      </S.StyledButton>
    </>
  );
};

export default CtaButtonWithDiscountInfo;
