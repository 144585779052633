import React, { useMemo, useState } from 'react';
import { Button } from '@naf/button';
import { Message } from '@naf/input';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FormField } from '@dtp/membership-service-types';

import { Spinner } from '../../components/spinner/Spinner';
import useSelector from '../../redux/typedHooks';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { Form } from '../../components/Form';
import * as S from './Styles';

type Props = {
  callToAction: {
    buttonText: string;
    url: string;
    type: string;
    internalEndpoint?: string;
    disabled?: boolean;
    fieldComponents?: FormField[];
  };
};

const CtaActivateButton = ({ callToAction }: Props) => {
  const [InternalEndpointActivateState, setInternalEndpointActivateState] = useState<
    'initial' | 'loading' | 'success' | 'error'
  >('initial');
  const application = useSelector((state) => state.application);
  const { simpleToken } = useAuth0Token();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onChange' });

  const callToActionFields = useMemo(
    () =>
      callToAction?.fieldComponents?.map((field) => {
        if (InternalEndpointActivateState === 'success') {
          return { ...field, isReadOnly: true };
        }
        return field;
      }),
    [callToAction.fieldComponents, InternalEndpointActivateState],
  );

  const handleCtaBlankExternalTracking = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        'Ocp-Apim-Subscription-Key': application.apimContentHub,
        Authorization: `Bearer ${simpleToken}`,
      },
    };
    try {
      setInternalEndpointActivateState('loading');
      const data = getValues();
      const response = await axios.post(
        callToAction.internalEndpoint as string,
        { fields: Object.entries(data).map(([key, value]) => ({ id: key, value })) },
        config,
      );
      if (response.status === 200) {
        setInternalEndpointActivateState('success');
      } else {
        setInternalEndpointActivateState('error');
      }
    } catch (error) {
      setInternalEndpointActivateState('error');
    }
  };

  return (
    <S.CtaActivateButtonWrapper>
      {callToAction.fieldComponents?.length > 0 ? (
        <Form fieldComponents={callToActionFields} control={control} handleSubmit={handleSubmit} errors={errors} />
      ) : null}
      <Button
        onClick={handleCtaBlankExternalTracking}
        disabled={callToAction.disabled || InternalEndpointActivateState === 'success'}
        target="_blank"
      >
        {
          {
            initial: callToAction.buttonText,
            loading: <Spinner />,
            success: 'Aktivert',
            error: <Message error>Noe gikk galt</Message>,
          }[InternalEndpointActivateState]
        }
      </Button>
    </S.CtaActivateButtonWrapper>
  );
};

export default CtaActivateButton;
