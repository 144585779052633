import qs from 'qs';

export const hasUrlParams = (url: string) => {
  const parsedLocation = new URL(url);
  return parsedLocation && !!parsedLocation.search;
};

export const addUrlParam = (queryParam: Record<string, string>, url: string) => {
  const hasParams = hasUrlParams(url);
  if (!hasParams) {
    return `${url}${qs.stringify(queryParam, { addQueryPrefix: true })}`;
  }
  return `${url}&${qs.stringify(queryParam)}`;
};
