import React from 'react';
import { Button } from '@naf/button';
import CtaActivateButton from './CtaActivateButton';
import useSelector from '../../redux/typedHooks';
import { addUrlParam } from '../../lib/addUrlParams';
import * as S from './Styles';
import CtaButtonWithDiscountInfo from './CtaButtonWithDiscountInfo';
import { ExternalActivationHiddenForm } from '../MyMembership/ProductFlyout/ExternalActivationHiddenForm';

export type CTA = {
  buttonText: string;
  url: string;
  type: 'ExternalUrl' | 'ExternalEndpointActivate' | 'InternalEndpointActivate';
  internalEndpoint?: string;
  disabled?: boolean;
  blank?: boolean;
  externalTransaction?: {
    activationUrl: string;
    activationMethod: 'POST' | 'GET' | 'PATCH' | 'PUT';
    headers: Record<string, string>;
    payload: Record<string, unknown>;
  };
};

type Props = {
  callToAction: CTA;
  urlParameters?: Record<string, string>;
  onClickBecomeAMember: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleCtaBlankExternalTracking: (c: CTA) => any;
  hideButton?: boolean;
};

const CtaButton = ({
  callToAction,
  urlParameters,
  onClickBecomeAMember,
  handleCtaBlankExternalTracking,
  hideButton = false,
}: Props) => {
  const {
    data: customerInformation,
    meta: { fetchState },
  } = useSelector((state) => state.myMembership.customerInformation);

  const callToActionUrl =
    callToAction?.url && Object.keys(urlParameters).length > 0
      ? addUrlParam(urlParameters, callToAction.url)
      : callToAction?.url || null;

  if (!callToActionUrl && callToAction?.type !== 'InternalEndpointActivate') return null;

  const ctaRenderSwitch = (callToActionType: CTA['type']) => {
    switch (callToActionType) {
      case 'ExternalEndpointActivate':
        return (
          <ExternalActivationHiddenForm
            externalActivationDetails={callToAction.externalTransaction}
            buttonText={callToAction.buttonText}
            shouldHaveActivationButton
          />
        );
      case 'InternalEndpointActivate':
        // show button that calls the api, no link involved
        return <CtaActivateButton callToAction={callToAction} />;
      default:
        // show button that handles linking
        return (
          <Button
            onClick={handleCtaBlankExternalTracking(callToAction)}
            href={callToActionUrl}
            target={callToAction.blank ? '_blank' : '_self'}
            disabled={callToAction.disabled}
          >
            {callToAction.buttonText}
          </Button>
        );
    }
  };

  return (
    <>
      {!hideButton && (
        <S.CTAWrapper>
          {ctaRenderSwitch(callToAction.type)}
          {fetchState === 'success' && !customerInformation?.membership && (
            <Button onClick={onClickBecomeAMember} href="/bli-medlem" variant="outline">
              Bli medlem
            </Button>
          )}
        </S.CTAWrapper>
      )}
      {hideButton && <CtaButtonWithDiscountInfo onClickBecomeAMember={onClickBecomeAMember} />}
    </>
  );
};

export default CtaButton;
